import React, { useEffect } from "react";
import {
  FloatingHelper,
  ToggleSwitch,
  FormField,
  EmptyState,
  CustomModalLayout,
  TextButton,
  Heading,
  Modal,
  MessageModalLayout,
  Text,
  Card,
  Box,
  Page,
  Button,
  Cell,
  Layout,
  Input,
  WixDesignSystemProvider,
  Image,
  PageSection,
  Badge,
  MarketingLayout,
  AnnouncementModalLayout,
  Thumbnail,
  MarketingPageLayoutContent,
  MarketingPageLayout,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import * as Icons from "@wix/wix-ui-icons-common";
import "./App.css";
import { ReactTagManager } from "react-gtm-ts";
import * as basePlatform from "@certifiedcode/base";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  ReactTagManager.init(tagManagerArgs);

  const instance = new URLSearchParams(window.location.search).get("instance");
  const token = new URLSearchParams(window.location.search).get("token");

  const [isHighlighted, setIsHighlighted] = React.useState(false);
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [viewDemo, setViewDemo] = React.useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isOpening, setIsOpening] = React.useState(false);
  const [shownSettingsModal, setShownSettingsModal] = React.useState(false);
  const [secretKey, setSecretKey] = React.useState("");
  const [showVideo, setShowVideo] = React.useState(false);
  const [publicKey, setPublicKey] = React.useState("");
  const [isInteractiveDemoOpen, setIsInteractiveDemoOpen] =
    React.useState(false);
  const [InteractiveDemoOpen, setInteractiveDemoOpen] =
    React.useState(false);
  const [isBusinessAppModalOpened, setIsBusinessAppModalOpened] =
    React.useState(false);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  useEffect(() => {
    basePlatform.initialize({
      baseURL: "https://studiobaseapps.wixsite.com/stripe/_functions", // Replace with your API base URL
      applicationID: "53314207-ce32-4955-aee6-44071f6fc809", // Replace with your application ID
    });
    basePlatform.setIdentity(instance);
    basePlatform.setInstance(setInstanceData, setIsUpgraded);
    getSettings();
  }, []);

  // if (!instance) {
  //   return null
  // }

  const BASE_URL = `https://studiobaseapps.wixsite.com/stripe/_functions`;

  function setSettings() {
    // if (isUpgraded) {
    if (!open) {
      if (secretKey === "") {
        setShownSettingsModal(true);
        return;
      }
    }
    const account =
      !open === true
        ? {
            secretKey,
            publicKey,
          }
        : {};
    setIsOpening(true);
    fetch(BASE_URL + "/settings", {
      method: "POST",
      headers: {
        Authorization: instance || "",
      },
      body: JSON.stringify({
        isEnabled: !open,
        ...account,
      }),
    })
      .then(() => {
        setShownSettingsModal(false);
        setIsOpening(false);
        setOpen(!open);
        setSecretKey("");
        setPublicKey("");
      })
      .catch(() => {});
    // } else {
    //   setIsUpgradeModalOpen(true)
    // }
  }

  function getSettings() {
    setIsOpening(true);
    fetch(BASE_URL + "/settings", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setIsOpening(false);
        setOpen(data.isEnabled);
      })
      .catch(() => {});
  }

  const isPremiumApp = true;

  const YoutubeEmbed = ({ embedId }: any) => (
    <iframe
      width="560px"
      height="315px"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  );

  const renderThumbnail = ({ title, subtitle, id }: any) => (
    <Thumbnail
      onClick={() => {
        window.Paddle.Checkout.open({
          product: id,
          email: (instanceData as any)?.site?.ownerInfo?.email,
          allowQuantity: false,
        });
      }}
    >
      <Box padding="18px">
        <Box gap="12px" verticalAlign="middle">
          <Icons.Premium />
          <Box direction="vertical">
            <Text size="medium" weight="bold">
              {title}
            </Text>
            <Box>
              <Text size="small" secondary>
                {subtitle}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Thumbnail>
  );

  if (token) {
    return <></>;
  }
  const openInteractiveDemo = () => {
    setInteractiveDemoOpen(true);
  };

 
  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height="100vh">
        <Page.Header
          title={
            <Box gap={"SP2"}>
              <Heading>Get Paid with Stripe</Heading>
            </Box>
          }
          actionsBar={
            <Box gap={"SP2"} direction="horizontal">
              {isUpgraded && (
                <Button
                  prefixIcon={<Icons.Reviews />}
                  skin="inverted"
                  as="a"
                  href="https://bridget.reviews.certifiedcode.us/53314207-ce32-4955-aee6-44071f6fc809"
                  target="_blank"
                >
                  Write a review
                </Button>
              )}
              <Button
                skin="premium"
                as="a"
                href={basePlatform.getUpgradeUrl()}
                target={"_blank"}
                prefixIcon={<Icons.PremiumFilled />}
              >
                {isUpgraded ? "Manage Plan" : "Accept Online Payments"}
              </Button>
            </Box>
          }
        />
        <Page.Content>
          <Modal
            isOpen={isBusinessAppModalOpened}
            onRequestClose={() => setIsBusinessAppModalOpened(false)}
            shouldCloseOnOverlayClick
            screen="desktop"
          >
            <CustomModalLayout
              onCloseButtonClick={() => {
                setIsBusinessAppModalOpened(false);
              }}
              title="Advanced Integration"
              content={
                <Card>
                  <MarketingPageLayoutContent
                    title="Access to Stripe Integration with Wix Business Solutions, including Stores, Bookings and more."
                    content={
                      <Text>
                        <ul>
                          <li>
                            Accept payments through the Wix Stores and Bookings
                          </li>
                          <li>Seamless integration for online payments</li>
                          <li>Automatically update payment status with Wix</li>
                          <Box
                            alignContent="center"
                            gap="SP1"
                            direction="horizontal"
                          >
                            <li>Pay anywhere in Wix</li>
                            <Badge size="tiny" skin="warningLight">
                              New
                            </Badge>
                          </Box>
                        </ul>
                      </Text>
                    }
                    actions={
                      <Layout>
                        <Cell>
                          <Layout cols={1} gap="12px">
                            <Text size="small" weight="bold">
                              Choose your plan
                            </Text>
                            {renderThumbnail({
                              title: "Lifetime",
                              subtitle:
                                "Single purchase of US$199, no renewal required.",
                              id: 858919,
                            })}
                            {renderThumbnail({
                              title: "Monthly Billing",
                              subtitle:
                                "Try it for free for 14 days, then automatically renew for US$14/month. Cancel renewal at any time.",
                              id: 858918,
                            })}
                          </Layout>
                        </Cell>
                        <Cell>
                          <Text size="tiny">
                            Sales tax may be charged at checkout. You can
                            preview the final charge before payment. By
                            continuing you agree to{" "}
                            <a
                              href="https://www.certifiedcode.us/legal/terms-of-services"
                              target="_blank"
                            >
                              Certified Code's Terms of Services
                            </a>
                          </Text>
                        </Cell>
                        <Cell></Cell>
                      </Layout>
                    }
                  />
                </Card>
              }
            />
          </Modal>
          <Modal isOpen={showVideo}>
            <AnnouncementModalLayout
              title="Getting Started"
              onCloseButtonClick={() => {
                setShowVideo(false);
              }}
            >
              <YoutubeEmbed embedId="6lqD56HYkok" />
            </AnnouncementModalLayout>
          </Modal>
          <Modal isOpen={viewDemo}>
            <AnnouncementModalLayout
              title="Stripe + Wix eCommerce"
              onCloseButtonClick={() => {
                setViewDemo(false);
              }}
            >
              <YoutubeEmbed embedId="yjMaOmmVSMo" />
            </AnnouncementModalLayout>
          </Modal>
          <Modal
            isOpen={shownSettingsModal}
            onRequestClose={() => setShownSettingsModal(false)}
            shouldCloseOnOverlayClick
            screen="desktop"
          >
            <CustomModalLayout
              primaryButtonText="Connect"
              secondaryButtonText="Cancel"
              onCloseButtonClick={() => setShownSettingsModal(false)}
              secondaryButtonOnClick={() => setShownSettingsModal(false)}
              primaryButtonOnClick={() => setSettings()}
              removeContentPadding
              onHelpButtonClick={() => {
                window.open(
                  "https://stripe.com/docs/keys#reveal-an-api-secret-key-live-mode",
                  "_blank"
                );
              }}
              title="Connect to Stripe"
              content={
                <Page minWidth={0}>
                  <Page.Content>
                    <Box marginTop={5} display="block">
                      <Layout>
                        <Cell span={12}>
                          <Box direction="vertical" gap="SP2">
                            <FormField
                              label="Secret Key"
                              required
                              suffix={
                                <TextButton
                                  as="a"
                                  href="https://stripe.com/docs/keys#reveal-an-api-secret-key-live-mode"
                                  target="_blank"
                                  size="small"
                                  suffixIcon={<Icons.ExternalLinkSmall />}
                                >
                                  Steps to find
                                </TextButton>
                              }
                            >
                              <Input
                                required
                                clearButton
                                value={secretKey}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => setSecretKey(e.target.value)}
                              />
                            </FormField>
                            <FormField
                              label="Public Key"
                              required
                              // suffix={
                              //   <TextButton
                              //     as="a"
                              //     href="https://support.certifiedcode.us/en/articles/8719738-stripe-pay-button-about-inline-checkout"
                              //     target="_blank"
                              //     size="small"
                              //     suffixIcon={<Icons.ExternalLinkSmall />}
                              //   >
                              //     Learn more
                              //   </TextButton>
                              // }
                            >
                              <Input
                                clearButton
                                value={publicKey}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => setPublicKey(e.target.value)}
                              />
                            </FormField>
                          </Box>
                        </Cell>
                      </Layout>
                    </Box>
                  </Page.Content>
                </Page>
              }
            />
          </Modal>
          <Modal
            isOpen={isUpgradeModalOpen}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => setIsUpgradeModalOpen(false)}
          >
            <AnnouncementModalLayout
              theme="premium"
              title="Accept live payments without limits"
              primaryButtonText="Upgrade"
              // linkText="Learn more"
              primaryButtonOnClick={() => {
                window.open(
                  `https://www.wix.com/apps/upgrade/${"53314207-ce32-4955-aee6-44071f6fc809"}?appInstanceId=${
                    (instanceData as any)["instance"]["instanceId"]
                  } `
                );
              }}
              // linkOnClick={() => { window.open('https://support.certifiedcode.us/en/articles/7953534-getting-started-Stripe-pay-button#h_b92f8de467') }}
              onCloseButtonClick={() => setIsUpgradeModalOpen(false)}
            >
              <Text>
                Accept live payments via Get Paid with Stripe. Upgrade to accept
                more payments.
              </Text>
            </AnnouncementModalLayout>
          </Modal>
          <Modal isOpen={isModalOpen} screen="desktop">
            <MessageModalLayout
              primaryButtonText="Refresh"
              primaryButtonOnClick={() => window.location.reload()}
              title="Ops, something went wrong"
              content={
                <Text>Please refresh this page, or contact support.</Text>
              }
            />
          </Modal>

          <Modal
            isOpen={isInteractiveDemoOpen}
            screen="desktop"
            shouldDisplayCloseButton={true}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => setIsInteractiveDemoOpen(false)}
          >
            <CustomModalLayout
              removeContentPadding={true}
              content={
                <iframe
                  src="https://demo.arcade.software/0PxYlTZUNVLy36nOyEma?embed&show_copy_link=true"
                  title="Get Paid with Stripe"
                  frameBorder="0"
                  loading="lazy"
                  allowFullScreen
                  allow="clipboard-write"
                  // style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;color-scheme: light;"
                  style={{
                    width: 800,
                    height: 500,
                  }}
                ></iframe>
              }
            />
          </Modal>
          <Modal
            isOpen={InteractiveDemoOpen}
            screen="desktop"
            shouldDisplayCloseButton={true}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => setInteractiveDemoOpen(false)}
          >
             <CustomModalLayout
              removeContentPadding={true}
              content={
                <div style={{ position: "relative", paddingBottom: "calc(56.22% + 41px)", height: 0, width: "100%" }}>
                  <iframe
                    src="https://demo.arcade.software/6TKsYdHEVkc9RL8KrbFi?embed&embed_mobile=inline&embed_desktop=inline&show_copy_link=true"
                    title="Get Paid with Stripe | Getting Started"
                    frameBorder="0"
                    loading="lazy"
                    allow="clipboard-write"
                    allowFullScreen
                    style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", colorScheme: "light" }}
                  ></iframe>
                </div>
              }
            />
          </Modal>
          <Layout>
            {!isUpgraded && (
              <Cell span={12}>
                <Card>
                  <MarketingLayout
                    size="tiny"
                    actions={
                      <Box gap={1} verticalAlign="middle">
                        <Button
                          dataHook="dealer-placement-main-cta"
                          as="a"
                          skin="premium"
                          target="_blank"
                          href={basePlatform.getUpgradeUrl()}
                        >
                          Upgrade
                        </Button>
                        {/* <Button
                          dataHook="dealer-placement-secondary-cta"
                          skin="premium"
                          priority="secondary"
                          onClick={() => {
                            window.Intercom(
                              "showNewMessage",
                              "I would like to learn more about Get Paid with Stripe premium plan."
                            );
                          }}
                        >
                          Talk with Sales
                        </Button> */}
                      </Box>
                    }
                    title="Upgrade to start selling online"
                    description="Customers can’t check out right now because you don’t have a plan that supports accepting payments."
                  ></MarketingLayout>
                </Card>
              </Cell>
            )}
            {isUpgraded ? (
              <Cell span={12}>
                <Card>
                  <MarketingLayout
                    size="tiny"
                    title="Looking for Stripe + Wix eCommerce instead?"
                    description="Pay Buttons enables you to accept payment everywhere on the site, while Advanced Integration enables possibility working with Wix business apps."
                    actions={
                      <Box gap="SP1">
                        <Button
                          size="small"
                          suffixIcon={<Icons.PlaySmall />}
                          onClick={() => {
                            setViewDemo(true);
                          }}
                        >
                          Watch Demo
                        </Button>
                        <Button
                          size="small"
                          suffixIcon={<Icons.ExternalLinkSmall />}
                          skin="inverted"
                          onClick={() => {
                            setIsBusinessAppModalOpened(true);
                          }}
                        >
                          See Pricing
                        </Button>
                      </Box>
                    }
                  />
                </Card>
              </Cell>
            ) : (
              <></>
            )}
           
            <Cell span={12}>
              <Card>
                <Card.Header
                  title={
                    <Box gap={"SP2"}>
                      <Heading size="medium" as="h2">
                        Accept Online Payments
                      </Heading>
                    </Box>
                  }
                  suffix={
                    <ToggleSwitch
                      onChange={() => {
                        setSettings();
                      }}
                      size="medium"
                      checked={open}
                      disabled={isOpening}
                    />
                  }
                />
                {/* <Card.Subheader title={<Text size='small'>Pay Button can be placed everywhere on your site via Editor, and supports custom amount or pre-defined amount payment. <b>Note: Pay Button does not integrate with Wix eCommerce.</b></Text>} suffix={
                  <Button skin='light' size="small" onClick={() => { setIsHighlighted(true) }}>Explore advanced integration</Button>
                } /> */}
                {/* <Card.Subheader
                  title={
                    <Box direction="vertical" gap="SP2">
                      <Box gap={"SP1"} verticalAlign="middle">
                        <Text size="small">Payment methods: </Text>
                        <Image borderRadius={0} src={ApplePay} width="36px" />
                        <Image borderRadius={0} src={GooglePay} width="36px" />
                        <Image borderRadius={0} src={Visa} width="36px" />
                        <Image borderRadius={0} src={MasterCard} width="36px" />
                        <Image borderRadius={0} src={Amex} width="36px" />
                        <Image borderRadius={0} src={Discover} width="36px" />
                        <Image borderRadius={0} src={JCB} width="36px" />
                        <Image borderRadius={0} src={DinersClub} width="36px" />
                        <Image borderRadius={0} src={UnionPay} width="36px" />
                        <Image borderRadius={0} src={Maestro} width="36px" />
                        <Image borderRadius={0} src={Maestro} width="36px" />
                        <Text size="small" skin="disabled">
                          + 30 payments method available in Stripe. Subjected to
                          Stripe availablility.
                        </Text>
                      </Box>
                    </Box>
                  }
                /> */}
                <Card.Content>
                  {open && isUpgraded ? (
                    <EmptyState
                      theme="section"
                      title="Great! You're ready to accept live payments."
                      subtitle="To change your Stripe account, turn off “Accept Live Payments”, then turn it on again and fill in the Stripe secret key again."
                    >
                      <TextButton
                        prefixIcon={<Icons.ExternalLink />}
                        as={"a"}
                        href={
                          (instanceData as any)["site"]
                            ? (instanceData as any)["site"]["url"]
                            : ""
                        }
                        target={"_blank"}
                      >
                        Open Live Site
                      </TextButton>
                    </EmptyState>
                  ) : (
                    <></>
                  )}
                </Card.Content>
              </Card>
            </Cell>
             {/* interactive demo Cell */}
             <Cell span={12}>
              <Card>
                <MarketingLayout
                  size="tiny"
                  actions={
                    <Box padding="4px" verticalAlign="middle">
                      <Box marginTop="3px">
                        <Button
                          size="small"
                          skin="premium"
                          onClick={openInteractiveDemo}
                        >
                          Watch video
                        </Button>
                      </Box>
                    </Box>
                  }
                  title="Learn how to setup Stripe"
                  description="Estimated time: 5 minutes"
                ></MarketingLayout>
              </Card>
            </Cell>
             {/* interactive demo Cell */}
            {/* <Cell>
              <PageSection title="Suggested for you" showDivider />
            </Cell> */}
            {/* <Cell span={6}>
              <Card>
                <MarketingLayout
                  title="Learn how to use Get Paid with Stripe"
                  description="Discover how to use Get Paid with Stripe to accept payments on your site."
                  actions={
                    <Box gap="SP1">
                      <Button
                        suffixIcon={<Icons.AIFilled />}
                        size="small"
                        skin="standard"
                        onClick={() => {
                          setIsInteractiveDemoOpen(true);
                        }}
                      >
                        Play Interactive Demo
                      </Button>
                      <Button
                        suffixIcon={<Icons.PlayFilled />}
                        size="small"
                        skin="standard"
                        priority="secondary"
                        onClick={() => {
                          setShowVideo(true);
                        }}
                      >
                        Watch Video
                      </Button>
                    </Box>
                  }
                  size="tiny"
                  badge={<Badge size="small">Tutorial</Badge>}
                />
              </Card>
            </Cell> */}
            {/* <Cell span={6}>
              <Card>
                <MarketingLayout
                  title="Contact support"
                  description="Need help? Talk with our team to get answers to your questions."
                  actions={
                    <Button
                      size="small"
                      onClick={() => {
                        window.Intercom(
                          "showNewMessage",
                          "I need help with Get Paid with Stripe."
                        );
                      }}
                    >
                      Live Chat
                    </Button>
                  }
                  size="tiny"
                  hiddenBadge
                  alignItems="stretch"
                />
              </Card>
            </Cell> */}
            {/* <Cell span={12}>
              <BC.Recommends appId="53314207-ce32-4955-aee6-44071f6fc809" />
            </Cell>
            <Cell span={12}>
              <BC.Offers appId="53314207-ce32-4955-aee6-44071f6fc809" />
            </Cell> */}
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
